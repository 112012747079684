<template>
  <main class="page blog-open-page">
    <div class="container-padding">
      <div v-if="blog" class="page__inner">
        <SetHeader :blog="blog" />
        <div class="blog-open-page__body">
          <span v-if="blog.created_at" class="blog-open-page__date">
            {{ blog.created_at | formatDate }}
          </span>
          <EditorJSComponent v-if="blog.text" :text="JSON.parse(blog.text)" />
          <!--          <WrapperComponent :data="{ title: 'Другие публикации', link: '#' }" row>-->
          <!--            <div class="blog-open-page__similar">-->
          <!--              &lt;!&ndash;              <BlogCard v-for="_ in 2" :key="_" />&ndash;&gt;-->
          <!--            </div>-->
          <!--          </WrapperComponent>-->
          <!--        </div>-->
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import SetHeader from "./components/SetHeader.vue";
import BLOG_OPEN_PAGE from "@/graphql/pages/landing/articles_item.graphql";
import EditorJSComponent from "components/EditorJSComponent.vue";

export default {
  name: "BlogPage",
  components: {
    SetHeader,
    EditorJSComponent,
  },
  async asyncData({ apollo, store, route }) {
    await apollo.clients.landing
      .query({
        query: BLOG_OPEN_PAGE,
        variables: {
          id: parseInt(route.params.id) || undefined,
        },
        fetchPolicy: "network-only",
      })
      .then(({ data }) => {
        store.state.blog_open_page = data.articles_item;
      });
  },

  data() {
    return {
      loading: false,
    };
  },

  computed: {
    blog() {
      return this.$store.state.blog_open_page;
    },
  },
};
</script>

<style lang="stylus">
.blog-open-page {

  &__body {
    display flex
    flex-direction column
    gap 20px
    max-width 872px
    width 100%
    margin 0 auto
  }

  &__date {
    color: var(--text-black);
    font-size: 1.25em;
    font-weight: 400;
    line-height: 23px;
  }

  //&__similar {
  //  display grid
  //  grid-template-columns repeat(2,1fr)
  //  gap 24px
  //}
}
</style>
